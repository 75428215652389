import React, { useContext } from 'react';
import context from '../context';
import styled from 'styled-components';

const Logo = styled.img`
  
  border-radius: 5px;
  background-color: #575757;
  max-width: 25%;
  max-height: 30%;
  object-fit: contain;
  object-position: center;
  
  
`;

export default ({ dark, mobile }) => {
  const state = useContext(context);

  return (
    <Logo src={dark ? state.logo : mobile ? require('../images/logo-dark.png') : state.logo} alt="Logo" />
  );
};
